// src/components/NodeStatus/Pagination/Pagination.js

import React from 'react';
import { Pagination as MuiPagination, Box, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Pagination = ({ currentPage, totalPages, onPageChange, itemsPerPage, setItemsPerPage }) => {
  const { t } = useTranslation();
  const handlePageChange = (event, value) => {
    if (value >= 1 && value <= totalPages) {
      onPageChange(value);
    }
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        right: '45px',
        bottom: '45px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <MuiPagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        variant="outlined"
        shape="rounded"
        siblingCount={1}
        boundaryCount={1}
        sx={{
          '& .MuiPaginationItem-root': {
            width: '30px',
            height: '30px',
          },
        }}
      />
      <Select
        value={itemsPerPage}
        onChange={handleItemsPerPageChange}
        sx={{
          ml: 3,
          width: '30px',
          height: '30px',
          minWidth: '110px',
          color: '#555'
        }}
      >
        <MenuItem value={10}>10 {t('nodeStatus.rowsPage')}</MenuItem>
        <MenuItem value={50}>50 {t('nodeStatus.rowsPage')}</MenuItem>
        <MenuItem value={100}>100 {t('nodeStatus.rowsPage')}</MenuItem>
        <MenuItem value={500}>500 {t('nodeStatus.rowsPage')}</MenuItem>
      </Select>
    </Box>
  );
};

export default Pagination;
