// src/api/auth.js

import instance from './axiosInstance';

export const register = (userData) => {
  return instance.post('/auth/register', userData);
};

export const login = (userData) => {
  return instance.post('/auth/login', userData);
};

// Check the user's authentication status (for protected routes)
export const checkAuth = () => {
  return instance.get('/auth/check');  // Used to verify if the user is authenticated
};
