// src/pages/Home/HomePage.js

import React, { useContext } from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../contexts/AuthContext'; // Import AuthContext

function HomePage() {
  const { t } = useTranslation();
  const { userPermissions } = useContext(AuthContext);

  // Ensure userPermissions is at least an empty array before checking permissions
  const hasSpecialPermission = (userPermissions || []).includes('special_permission');

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        height: 'calc(100vh - 105px)', // Ensure full height minus the header (Navbar) height
        width: '100%',
      }}
    >
      <Box
        sx={{
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: '#ffffff',
          maxWidth: '650px',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Typography variant="h3" component="h1" gutterBottom>
          {t('homePage.welcomeToTheHomePage')}
        </Typography>
        <Typography variant="body1" component="p" color="textSecondary">
          {t('homePage.content')}
        </Typography>

        {hasSpecialPermission && (
          <Typography variant="h6" component="p" color="primary">
            {t('homePage.specialContent')} {/* Content visible only to users with special permission */}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default HomePage;
