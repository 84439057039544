// src/api/nodeStatusService.js

import instance from './axiosInstance';

export const getNodeStatus = async (page, limit) => {
  try {
    const response = await instance.get(`/node_status?page=${page}&limit=${limit}`);
    // console.log("API response:", response);
    const { data, has_next, has_prev, next_num, prev_num, pages } = response.data;
    return {
      data: data || [],
      hasNext: has_next,
      hasPrev: has_prev,
      nextPage: next_num,
      prevPage: prev_num,
      totalPages: pages,
    };
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};
