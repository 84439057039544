// src/components/Auth/LoginForm.js

import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, Button, Box, Typography } from '@mui/material';
import * as Yup from 'yup';
import { login } from '../../api/auth';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const navigate = useNavigate();
  const initialValues = { username: '', password: '' };

  const validationSchema = Yup.object({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    login(values)
      .then((response) => {
        if (response.status === 200) {
          // Successfully logged in, redirect to the main page
          navigate('/home');
        }
      })
      .catch(() => {
        setErrors({ password: 'Login failed, please try again' });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <Box mb={2}>
            <Field
              name="username"
              as={TextField}
              label="Username"
              variant="outlined"
              fullWidth
              error={touched.username && !!errors.username}
              helperText={touched.username && errors.username}
              sx={{ mb: 2, borderRadius: 1 }}
            />
          </Box>
          <Box mb={2}>
            <Field
              name="password"
              as={TextField}
              type="password"
              label="Password"
              variant="outlined"
              fullWidth
              error={touched.password && !!errors.password}
              helperText={touched.password && errors.password}
              sx={{ mb: 2, borderRadius: 1 }}
            />
          </Box>
          {errors.password && (
            <Typography color="error" variant="body2" align="center" gutterBottom>
              {errors.password}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isSubmitting}
            sx={{ borderRadius: 1, paddingY: 1 }}
          >
            LOGIN
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
