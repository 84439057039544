// src/components/common/AddButton.js

import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const AddButton = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      sx={{
        minWidth: 45,
        width: 15,  // Adjust button width
        height: 30, // Adjust button height
        borderRadius: '5px',
        border: '1px solid #DADCE0',
        backgroundColor: '#FFFFFF',
        color: '#5F6368',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
          backgroundColor: '#E8F0FE',
          borderColor: '#4285F4',
          color: '#4285F4',
        },
      }}
    >
      <AddIcon 
        sx={{
          fontSize: 15,  // Resize the "+" symbol
        }}
      />
    </Button>
  );
};

export default AddButton;
