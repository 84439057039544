// src/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi) // Use HttpApi to load translation files
  .use(LanguageDetector) // Use LanguageDetector to automatically detect the user's language
  .use(initReactI18next) // Initialize react-i18next
  .init({
    fallbackLng: 'en-US', // Default language if detection fails
    supportedLngs: ['en', 'en-US', 'zh', 'zh-CN'], // List of supported languages
    debug: true, // Enable debug mode for development
    interpolation: {
      escapeValue: false, // Disable escaping to prevent XSS attacks (React already does this)
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Path to translation files
    },
    detection: {
      order: ['localStorage', 'cookie', 'htmlTag', 'path', 'subdomain'], // Order in which the detector checks for language preference
      caches: ['localStorage', 'cookie'], // Where the detected language should be stored
    },
  });

export default i18n;
