// src/components/NodeManagement/NodeTable/NodeTable.js

import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import NodeTableRow from './NodeTableRow';

const NodeTable = ({ data, onEditNode, onDeleteNode }) => {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} elevation={0} sx={{ marginTop: '10px', height: '750px', border: 'none', overflowY: 'auto' }}>
      <Table sx={{ minWidth: 650 }} aria-label="node table">
        <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: '#F8F8F8', zIndex: 1 }}>
          <TableRow>
            <TableCell sx={{ padding: '8px', fontWeight: 'bold', textAlign: 'center' }}>{t('id')}</TableCell>
            <TableCell sx={{ padding: '8px', fontWeight: 'bold', textAlign: 'center' }}>{t('host')}</TableCell>
            <TableCell sx={{ padding: '8px', fontWeight: 'bold', textAlign: 'center' }}>{t('port')}</TableCell>
            <TableCell sx={{ padding: '8px', fontWeight: 'bold', textAlign: 'center' }}>{t('username')}</TableCell>
            <TableCell sx={{ padding: '8px', fontWeight: 'bold', textAlign: 'center' }}>{t('remote_path')}</TableCell>
            <TableCell sx={{ padding: '8px', fontWeight: 'bold', textAlign: 'center' }}>{t('intermediate_path')}</TableCell>
            <TableCell sx={{ padding: '8px', fontWeight: 'bold', textAlign: 'center' }}>{t('nodeManagement.actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && Array.isArray(data) && data.length > 0 ? (
            data.map((node) => (
              <NodeTableRow key={node.id} node={node} onEditNode={onEditNode} onDeleteNode={onDeleteNode} />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Typography variant="h6" color="textSecondary">
                    {t('nodeManagement.nodeEditDrawer.noDataAvailable')}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NodeTable;
