// src/components/NodeManagement/NodeManagementPage/NodeManagementPage.js

import React, { useState, useEffect } from 'react';
import NodeTable from '../NodeTable/NodeTable';
import Pagination from '../Pagination';
import NodeEditDrawer from '../NodeEditDrawer';
import { getNodeList, deleteNode } from '../../../api/nodeManagementService';
import AddButton from '../../common/AddButton';
import { Box } from '@mui/material';

const NodeManagementPage = () => {
    const [nodes, setNodes] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [editingNode, setEditingNode] = useState(null);

    useEffect(() => {
        fetchNodeList(currentPage);
    }, [currentPage]);

    const fetchNodeList = async (page) => {
        try {
            const { data, pages } = await getNodeList(page);
            setNodes(data || []);
            setTotalPages(pages || 1);
        } catch (error) {
            console.error('Failed to fetch node list:', error);
            setNodes([]);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleAddNode = () => {
        setEditingNode(null);
        setDrawerOpen(true);
    };

    const handleEditNode = (node) => {
        setEditingNode(node);
        setDrawerOpen(true);
    };

    const handleDeleteNode = async (node) => {
        try {
            await deleteNode(node.id);
            fetchNodeList(currentPage);
        } catch (error) {
            console.error('Failed to delete node:', error);
        }
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
        setEditingNode(null);
    };

    // Refresh the list after saving the node
    const handleNodeSaved = () => {
        fetchNodeList(currentPage);
        handleCloseDrawer();
    };

    return (
        <Box sx={{ position: 'relative', padding: 2 }}>
            <AddButton 
                onClick={handleAddNode} 
                sx={{ 
                    position: 'absolute',
                    top: 10, // Adjust the top distance smaller and closer to the table
                    left: 10, // Adjust left distance
                }} 
            />
            <Box sx={{ marginTop: '15px' }}>
                <NodeTable 
                    data={nodes} 
                    onEditNode={handleEditNode} 
                    onDeleteNode={handleDeleteNode} 
                />
                <Pagination 
                    currentPage={currentPage} 
                    totalPages={totalPages} 
                    onPageChange={handlePageChange} 
                />
            </Box>
            <NodeEditDrawer 
                open={isDrawerOpen} 
                onClose={handleCloseDrawer} 
                node={editingNode} 
                onSave={handleNodeSaved} 
            />
        </Box>
    );
};

export default NodeManagementPage;
