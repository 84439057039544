// src/pages/Login/LoginPage.js

import React, { useContext } from 'react';
import LoginForm from '../../components/Auth/LoginForm';
import { Container, Typography, Box, Link } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';

function LoginPage() {
  const { t } = useTranslation();
  const { setIsAuthenticated } = useContext(AuthContext);  // Retrieve the function to set the login status
  const navigate = useNavigate();

  // Callback function executed after successful login
  const handleLoginSuccess = () => {
    setIsAuthenticated(true);  // Set the user as logged in
    navigate('/home');  // Redirect to the homepage
  };

  return (
    <Container maxWidth="xs" style={{ marginTop: '10%', textAlign: 'center' }}>
      <Box
        sx={{
          boxShadow: 3,
          padding: 3,
          borderRadius: 2,
          backgroundColor: '#ffffff',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          {t('common.login')}
        </Typography>
        <LoginForm onLoginSuccess={handleLoginSuccess} />  {/* Pass the callback for successful login */}
        <Typography variant="body2" sx={{ marginTop: 2 }}>
          {t('auth.dontHaveAccount')} <Link component={RouterLink} to="/register">{t('common.register')}</Link>
        </Typography>
      </Box>
    </Container>
  );
}

export default LoginPage;
