// src/components/NodeStatus/NodeStatusPage/NodeStatusPage.js

import React, { useEffect, useState } from 'react';
import NodeStatusTable from '../NodeStatusTable/NodeStatusTable';
import Pagination from '../Pagination/Pagination';
import { getNodeStatus } from '../../../api/nodeStatusService';
import { fetchFileByConfigId, getNodeList } from '../../../api/nodeManagementService';
import { Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem, Box, Snackbar, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

const NodeStatusPage = () => {
  const { t } = useTranslation();
  const [nodeStatusData, setNodeStatusData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedNode, setSelectedNode] = useState('');
  const [nodeList, setNodeList] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    fetchNodeStatus(currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    if (nodeList.length > 0 && !selectedNode) {
      setSelectedNode(nodeList[0].id); // Default to select the first node.
    }
  }, [nodeList, selectedNode]);

  const fetchNodeStatus = async (page, limit) => {
    try {
      const { data, totalPages } = await getNodeStatus(page, limit);
      setNodeStatusData(data || []);
      setTotalPages(totalPages || 1);
    } catch (error) {
      console.error('Failed to fetch node status data:', error);
      setNodeStatusData([]);
    }
  };

  const fetchNodeList = async () => {
    try {
      const { data } = await getNodeList(1);
      setNodeList(data);
    } catch (error) {
      console.error('Failed to fetch node list:', error);
    }
  };

  const handleUpdateClick = () => {
    fetchNodeList();
    setOpenDialog(true);
  };

  const handleNodeSelection = (event) => {
    setSelectedNode(event.target.value);
  };

  const handleConfirmUpdate = async () => {
    if (selectedNode) {
      try {
        const result = await fetchFileByConfigId(selectedNode);
        if (result.status === 200) {
          setSnackbarMessage(t('nodeStatus.fileFetchedSuccessfully'));
          setSnackbarSeverity('success');
        } else if (result.status === 404) {
          setSnackbarMessage(t('nodeStatus.configurationNotFound'));
          setSnackbarSeverity('error');
        } else if (result.status === 500) {
          setSnackbarMessage(t('nodeStatus.failedToFetchFile'));
          setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
        setOpenDialog(false);
      } catch (error) {
        setSnackbarMessage(t('nodeStatus.failedToFetchFileDueToError'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        console.error('Failed to fetch file:', error);
      }
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1); // Reset to first page when number of entries per page changes
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" gutterBottom>
          {t('nodeStatus.nodeStatus')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdateClick}
        >
          {t('nodeStatus.update')}
        </Button>
      </Box>

      <NodeStatusTable data={nodeStatusData} />

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={handleItemsPerPageChange}
          onPageChange={handlePageChange}
        />
      </Box>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}
        PaperProps={{
          style: {
            minWidth: '400px',
          },
        }}
      >
        <DialogTitle>{t('nodeStatus.selectServerToUpdate')}</DialogTitle>
        <DialogContent>
          <Select
            value={selectedNode}
            onChange={handleNodeSelection}
            fullWidth
          >
            {nodeList.map((node) => (
              <MenuItem key={node.id} value={node.id}>
                {node.id} - {node.host}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            {t('nodeStatus.cancel')}
          </Button>
          <Button onClick={handleConfirmUpdate} color="primary">
            {t('nodeStatus.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NodeStatusPage;
