// src/components/NodeManagement/NodeTable/NodeTableRow.js

import React, { useState } from "react";
import { TableCell, TableRow, Button, Menu, MenuItem, ListItemIcon, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";

const NodeTableRow = ({ node, onEditNode, onDeleteNode }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEditClick = () => {
        onEditNode(node);
        handleMenuClose();
    };

    const handleDeleteClick = () => {
        setDialogOpen(true);
        handleMenuClose();
    };

    const handleConfirmDelete = () => {
        onDeleteNode(node);
        setDialogOpen(false);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <TableRow hover sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                <TableCell sx={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{node.id}</TableCell>
                <TableCell sx={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{node.host}</TableCell>
                <TableCell sx={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{node.port}</TableCell>
                <TableCell sx={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{node.username}</TableCell>
                <TableCell sx={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{node.remote_path}</TableCell>
                <TableCell sx={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{node.intermediate_path}</TableCell>
                <TableCell sx={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                    <Button
                        onClick={handleMenuOpen}
                        endIcon={<ArrowDropDownIcon />}
                        sx={{ color: "#1976d2", textTransform: "none" }}
                    >
                        {t('nodeManagement.actions')}
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleEditClick}>
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{t('nodeManagement.edit')}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handleDeleteClick}>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{t('nodeManagement.delete')}</ListItemText>
                        </MenuItem>
                    </Menu>
                </TableCell>
            </TableRow>

            {/* Confirmation Dialog */}
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                PaperProps={{
                    style: {
                        minWidth: '400px',  // Adjust dialog width
                    },
                }}
            >
                <DialogTitle>{t('nodeManagement.confirmDelete')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('nodeManagement.areYouSureToDelete', { name: node.username })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        {t('nodeManagement.cancel')}
                    </Button>
                    <Button onClick={handleConfirmDelete} color="secondary">
                        {t('nodeManagement.delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default NodeTableRow;
