// src/components/NodeStatus/NodeStatusTable/NodeStatusTable.js

import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import NodeStatusRow from './NodeStatusRow';

const NodeStatusTable = ({ data }) => {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} elevation={0} sx={{ marginTop: '30px', height: '735px', border: 'none', overflowY: 'auto' }}>
      <Table sx={{ minWidth: 650 }} aria-label="node status table">
        <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: '#F8F8F8', zIndex: 1 }}>
          <TableRow sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
            <TableCell sx={{ width: '50px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{t('nodeStatus.id')}</TableCell>
            <TableCell sx={{ width: '150px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{t('nodeStatus.name')}</TableCell>
            <TableCell sx={{ width: '200px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{t('nodeStatus.host')}</TableCell>
            <TableCell sx={{ width: '100px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{t('nodeStatus.port')}</TableCell>
            <TableCell sx={{ width: '100px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{t('nodeStatus.icmp_status')}</TableCell>
            <TableCell sx={{ width: '100px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{t('nodeStatus.tcp_status')}</TableCell>
            <TableCell sx={{ width: '200px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{t('nodeStatus.created_at')}</TableCell>
            <TableCell sx={{ width: '200px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{t('nodeStatus.updated_at')}</TableCell>
            <TableCell sx={{ width: '100px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{t('nodeStatus.actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && Array.isArray(data) && data.length > 0 ? (
            data.map((node) => <NodeStatusRow key={node.id} node={node} />)
          ) : (
            <TableRow>
              <TableCell colSpan={9}> {/* Updated colSpan to include the new column */}
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Typography variant="h6" color="textSecondary">
                    {t('nodeStatus.noDataAvailable')}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NodeStatusTable;
