// src/components/Layout/Navbar.js

import React from 'react';
import { Drawer, List, ListItem, ListItemText, Box, Typography, Divider } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';

const Sidebar = ({ nonce }) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      }}
      nonce={nonce}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ height: 64, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="h6" align="center">
            {t('common.appName')}
          </Typography>
        </Box>
        <Divider />
        <List>
          <ListItem
            button
            component={RouterLink}
            to="/home"
            selected={location.pathname === '/home'}
            sx={{
              '&.Mui-selected': {
                backgroundColor: 'primary.light',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: 'primary.main',
                },
              },
              paddingX: 3,
              paddingY: 2,
            }}
          >
            <HomeIcon sx={{ marginRight: 2 }} />
            <ListItemText primary={t('common.home')} />
          </ListItem>

          {/* Node-status Navigation Links */}
          <ListItem
            button
            component={RouterLink}
            to="/node-status"
            selected={location.pathname === '/node-status'}
            sx={{
              '&.Mui-selected': {
                backgroundColor: 'primary.light',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: 'primary.main',
                },
              },
              paddingX: 3,
              paddingY: 2,
            }}
          >
            <ListAltIcon sx={{ marginRight: 2 }} />
            <ListItemText primary={t('nodeStatus.nodeStatus')} />
          </ListItem>

          {/* Node-management Navigation Links */}
          <ListItem
            button
            component={RouterLink}
            to="/node-management"
            selected={location.pathname === '/node-management'}
            sx={{
              '&.Mui-selected': {
                backgroundColor: 'primary.light',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: 'primary.main',
                },
              },
              paddingX: 3,
              paddingY: 2,
            }}
          >
            <StorageOutlinedIcon sx={{ marginRight: 2 }} />
            <ListItemText primary={t('nodeManagement.nodeManagement')} />
          </ListItem>
          
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
