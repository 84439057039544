// src/pages/Register/RegisterPage.js

import React, { useContext, useEffect } from 'react';
import RegisterForm from '../../components/Auth/RegisterForm';
import { Container, Typography, Box, Link } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

function RegisterPage() {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/home');
    }
  }, [isAuthenticated, navigate]);

  return (
    <Container maxWidth="xs" style={{ marginTop: '10%', textAlign: 'center' }}>
      <Box
        sx={{
          boxShadow: 3,
          padding: 3,
          borderRadius: 2,
          backgroundColor: '#ffffff',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Register
        </Typography>
        <RegisterForm />
        <Typography variant="body2" sx={{ marginTop: 2 }}>
          Already have an account? <Link component={RouterLink} to="/login">Login here</Link>
        </Typography>
      </Box>
    </Container>
  );
}

export default RegisterPage;
