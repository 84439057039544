// src/emotionCache.js

import createCache from '@emotion/cache';

const createEmotionCache = () => {
  const insertionPoint = document.querySelector('meta[name="emotion-insertion-point"]');
  
  let nonce = '';
  if (typeof document !== 'undefined') {
    const metaNonce = document.querySelector('meta[name="csp-nonce"]');
    if (metaNonce) {
      nonce = metaNonce.getAttribute('content');
    }
  }

  return createCache({
    key: 'css',
    nonce: nonce,
    insertionPoint,
  });
};

export default createEmotionCache;
