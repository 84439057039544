// src/components/Auth/RegisterForm.js

import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, Button, Box } from '@mui/material';
import * as Yup from 'yup';
import { register, login } from '../../api/auth'; // Import both register and login
import { useNavigate } from 'react-router-dom';

const RegisterForm = () => {
  const navigate = useNavigate();

  const initialValues = {
    username: '',
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object({
    username: Yup.string().required('Username is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    register({
      username: values.username,
      password: values.password,
    })
      .then(() => {
        // Directly log in after registration
        return login({ username: values.username, password: values.password });
      })
      .then(() => {
        // After successful login, navigate to home
        navigate('/home');
        setSubmitting(false);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          setErrors({ username: 'This username is already registered' });
        }
        setSubmitting(false);
      });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <Box mb={2}>
            <Field
              name="username"
              as={TextField}
              label="Username"
              variant="outlined"
              fullWidth
              error={touched.username && !!errors.username}
              helperText={touched.username && errors.username}
              sx={{ mb: 2, borderRadius: 1 }}
            />
          </Box>

          <Box mb={2}>
            <Field
              name="password"
              as={TextField}
              type="password"
              label="Password"
              variant="outlined"
              fullWidth
              error={touched.password && !!errors.password}
              helperText={touched.password && errors.password}
              sx={{ mb: 2, borderRadius: 1 }}
            />
          </Box>
          <Box mb={2}>
            <Field
              name="confirmPassword"
              as={TextField}
              type="password"
              label="Confirm Password"
              variant="outlined"
              fullWidth
              error={touched.confirmPassword && !!errors.confirmPassword}
              helperText={touched.confirmPassword && errors.confirmPassword}
              sx={{ mb: 2, borderRadius: 1 }}
            />
          </Box>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isSubmitting}
            sx={{ borderRadius: 1, paddingY: 1 }}
          >
            REGISTER
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterForm;