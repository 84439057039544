// src/api/axiosInstance.js

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,  // Ensure the request includes a Cookie
});

// Intercepting Responses
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // Handle unauthorized errors by redirecting to the login page
        window.location.href = '/login';
      }
      if (error.response.status === 403) {
        // Handle insufficient permissions errors by redirecting to the insufficient permissions page
        window.location.href = '/no-permission';
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
