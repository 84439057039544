// src/components/Layout/MainLayout.js

import React from 'react';
import { Box } from '@mui/material';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

const MainLayout = ({ children, nonce }) => {
  return (
    <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
      <Sidebar nonce={nonce} />
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <Navbar nonce={nonce} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            marginTop: "85px",
            marginBottom: "20px",
            marginLeft: "20px",
            marginRight: "20px",
            backgroundColor: "#ffffff",
            // borderRadius: "8px",
            boxShadow: 3,
            overflow: "visible",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
