// src/api/nodeManagementService.js

import instance from './axiosInstance';

// Get node list (pagination)
export const getNodeList = async (page) => {
    try {
        const response = await instance.get(`/remote_server_config?page=${page}&limit=10`);
        const { data, pages, has_next, has_prev } = response.data;
        return {
            data: data || [],
            pages: pages || 1,
            hasNext: has_next,
            hasPrev: has_prev,
        };
    } catch (error) {
        console.error('Failed to fetch node list:', error);
        throw error;
    }
};

// Create a node configuration
export const createNode = async (nodeData) => {
    try {
        const response = await instance.post('/remote_server_config', nodeData);
        return response.data;
    } catch (error) {
        console.error('Failed to save node:', error);
        throw error;
    }
};

// Update node configuration
export const updateNode = async (nodeId, nodeData) => {
    try {
        const response = await instance.put(`/remote_server_config/${nodeId}`, nodeData);
        return response.data;
    } catch (error) {
        console.error('Failed to update node:', error);
        throw error;
    }
};

// Deleting a node configuration
export const deleteNode = async (nodeId) => {
    try {
        const response = await instance.delete(`/remote_server_config/${nodeId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to delete node:', error);
        throw error;
    }
};

// Fetch file from remote server by configuration ID
export const fetchFileByConfigId = async (configId) => {
    try {
        const response = await instance.post('/fetch_file', {
            config_id: configId  // Sending the config ID as part of the request body
        });
        return response;
    } catch (error) {
        console.error(`Failed to fetch file for config ID ${configId}:`, error);
        throw error;
    }
};
