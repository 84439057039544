// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CacheProvider } from '@emotion/react';
import createEmotionCache from './emotionCache';
import HomePage from './pages/Home/HomePage';
import LoginPage from './pages/Login/LoginPage';
import RegisterPage from './pages/Register/RegisterPage';
import Logout from './components/Auth/Logout';
import PrivateRoute from './components/PrivateRoute';
import MainLayout from './components/Layout/MainLayout';
import NodeStatusPage from './components/NodeStatus/NodeStatusPage/NodeStatusPage';
import NodeManagementPage from './components/NodeManagement/NodeManagementPage/NodeManagementPage';
import { AuthProvider } from './contexts/AuthContext';
import { NodeManagementProvider } from './contexts/NodeManagementContext';

function App() {
  const cache = createEmotionCache();

  return (
    <AuthProvider>
      <CacheProvider value={cache}>
        <Router>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/logout" element={<Logout />} />

            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <MainLayout>
                    <HomePage />
                  </MainLayout>
                </PrivateRoute>
              }
            />

            <Route
              path="/node-status"
              element={
                <PrivateRoute>
                  <MainLayout>
                    <NodeStatusPage />
                  </MainLayout>
                </PrivateRoute>
              }
            />

            <Route
              path="/node-management"
              element={
                <PrivateRoute>
                  <NodeManagementProvider>
                    <MainLayout>
                      <NodeManagementPage />
                    </MainLayout>
                  </NodeManagementProvider>
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </CacheProvider>
    </AuthProvider>
  );
}

export default App;
