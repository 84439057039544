// src/components/NodeStatus/NodeStatusTable/NodeStatusRow.js

import React, { useState } from "react";
import { TableCell, TableRow, Button, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';

const NodeStatusRow = ({ node }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = () => {
    const textToCopy = `${node.host}:${node.server_port}`;
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert(`${t('nodeStatus.copied')}: ${textToCopy}`);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
    handleMenuClose();
  };

  const handleIcmp = () => {
    const url = `https://ping.pe/+${node.host}`;
    window.open(url, '_blank');
    handleMenuClose();
  };

  const handleTcp = () => {
    const textToCopy = `${node.host}:${node.server_port}`;
    const url = `https://tcp.ping.pe/+${textToCopy}`;
    window.open(url, '_blank');
    handleMenuClose();
  };

  return (
    <TableRow hover sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
      <TableCell component="th" scope="row" sx={{ width: '50px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        {node.id}
      </TableCell>
      <TableCell sx={{ width: '150px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        {node.name || 'N/A'}
      </TableCell>
      <TableCell sx={{ width: '200px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        {node.host || 'N/A'}
      </TableCell>
      <TableCell sx={{ width: '100px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        {node.server_port || 'N/A'}
      </TableCell>
      <TableCell sx={{ width: '100px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        {node.icmp_status === 0 ? (
          <CheckCircleIcon sx={{ color: 'green' }} />
        ) : (
          <CancelIcon sx={{ color: 'red' }} />
        )}
      </TableCell>
      <TableCell sx={{ width: '100px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        {node.tcp_status === 0 ? (
          <CheckCircleIcon sx={{ color: 'green' }} />
        ) : (
          <CancelIcon sx={{ color: 'red' }} />
        )}
      </TableCell>
      <TableCell sx={{ width: '200px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        {node.creation_timestamp || 'N/A'}
      </TableCell>
      <TableCell sx={{ width: '200px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        {node.modification_timestamp || 'N/A'}
      </TableCell>
      <TableCell sx={{ width: '100px', padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
        <Button
          onClick={handleMenuClick}
          endIcon={<ArrowDropDownIcon />}
          sx={{ color: "#1976d2", textTransform: "none" }}
        >
          {t('nodeStatus.actions')}
        </Button>
        <Menu
          id="action-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleCopy}>
            <ListItemIcon>
              <ContentCopyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('nodeStatus.copy')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleIcmp}>
            <ListItemIcon>
              <LanguageIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>ICMP</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleTcp}>
            <ListItemIcon>
              <LanguageIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>TCP</ListItemText>
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default NodeStatusRow;
