// src/components/Auth/Logout.js

import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

const Logout = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    // Remove the token from local storage
    localStorage.removeItem('token');

    // Optional: Remove any authentication cookies (if you're using cookies)
    document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // Update authentication status
    setIsAuthenticated(false);

    // Redirect to login page
    navigate('/login');
  }, [navigate, setIsAuthenticated]);

  return null;
};

export default Logout;
