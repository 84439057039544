// src/components/NodeManagement/NodeEditDrawer.js

import React, { useState, useEffect } from 'react';
import { Drawer, Box, Button, TextField, IconButton, InputAdornment, Snackbar, Alert } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { createNode, updateNode } from '../../api/nodeManagementService';
import { useTranslation } from 'react-i18next';

const NodeEditDrawer = ({ open, onClose, node, onSave }) => {
    const { t } = useTranslation();
    const [intermediatePath, setIntermediatePath] = useState('/home/admin/pass/pass-server.db');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [privateKeyContent, setPrivateKeyContent] = useState('');
    const [host, setHost] = useState('');
    const [port, setPort] = useState('');
    const [remotePath, setRemotePath] = useState('/etc/pass-server/pass-server.db');
    const [username, setUsername] = useState('');

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        if (node) {
            setIntermediatePath(node.intermediate_path || '/home/admin/pass/pass-server.db');
            setPassword(node.password || '');
            setPrivateKeyContent(node.private_key_content || '');
            setHost(node.host || '');
            setPort(node.port ? parseInt(node.port, 10) : '');
            setRemotePath(node.remote_path || '/etc/pass-server/pass-server.db');
            setUsername(node.username || '');
        } else {
            resetFields();
        }
    }, [node]);

    const resetFields = () => {
        setIntermediatePath('/home/admin/pass/pass-server.db');
        setPassword('');
        setPrivateKeyContent('');
        setHost('');
        setPort('');
        setRemotePath('/etc/pass-server/pass-server.db');
        setUsername('');
    };

    const handlePrivateKeyChange = (e) => {
        const formattedKey = e.target.value.replace(/\s+/g, '');
        setPrivateKeyContent(formattedKey);
    };

    const handlePasswordVisibilityToggle = () => {
        setShowPassword(!showPassword);
    };

    const validateForm = () => {
        if (!host || typeof host !== 'string' || host.trim() === '') {
            setSnackbarMessage(t('nodeManagement.nodeEditDrawer.hostCanNotEmpty'));
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return false;
        }
        if (isNaN(port) || port <= 0) {
            setSnackbarMessage(t('nodeManagement.nodeEditDrawer.portMustBePositiveInteger'));
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return false;
        }
        if (!username || typeof username !== 'string' || username.trim() === '') {
            setSnackbarMessage(t('nodeManagement.nodeEditDrawer.usernameCanNotEmpty'));
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return false;
        }
        if ((!password || typeof password !== 'string' || password.trim() === '') &&
            (!privateKeyContent || typeof privateKeyContent !== 'string' || privateKeyContent.trim() === '')) {
            setSnackbarMessage(t('nodeManagement.nodeEditDrawer.pwOrPKMustBeProvided'));
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return false;
        }
        return true;
    };

    const handleSave = async () => {
        if (!validateForm()) {
            return;
        }

        const payload = {
            intermediate_path: intermediatePath,
            password,
            private_key_content: privateKeyContent,
            host,
            port: parseInt(port, 10),  // Ensure port is an integer
            remote_path: remotePath,
            username,
        };

        try {
            if (node && node.id) {
                await updateNode(node.id, payload);
                setSnackbarMessage(t('nodeManagement.nodeEditDrawer.updatedSuccessfully'));
            } else {
                await createNode(payload);
                setSnackbarMessage(t('nodeManagement.nodeEditDrawer.createdSuccessfully'));
            }
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            resetFields();
            if (onSave) onSave(); // Notify the parent component to refresh the list
        } catch (error) {
            if (error.response && error.response.data && error.response.data.code) {
                const errorCode = error.response.data.code;
                if (errorCode === 'only_one_auth_method_allowed') {
                    setSnackbarMessage(t('nodeManagement.nodeEditDrawer.provideEitherPwOrPKC'));
                } else {
                    setSnackbarMessage(t('nodeManagement.nodeEditDrawer.saveFailedPleaseTryAgain'));
                }
            } else {
                setSnackbarMessage(t('nodeManagement.nodeEditDrawer.saveFailedPleaseTryAgain'));
            }
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            console.error('Failed to save configuration:', error);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return; // Prevent closing the Snackbar by clickaway
        }
        setSnackbarOpen(false);
    };

    return (
        <>
            <Drawer anchor="right" open={open} onClose={onClose} sx={{ zIndex: 1300 }}>
                <Box sx={{ width: 400, padding: 2 }}>
                    <TextField
                        fullWidth
                        label={t('nodeManagement.nodeEditDrawer.host')}
                        variant="outlined"
                        margin="normal"
                        value={host}
                        onChange={(e) => setHost(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        label={t('nodeManagement.nodeEditDrawer.port')}
                        variant="outlined"
                        margin="normal"
                        value={port}
                        onChange={(e) => setPort(parseInt(e.target.value, 10))}
                        type="number"
                        inputProps={{ min: "1", step: "1" }}
                    />
                    <TextField
                        fullWidth
                        label={t('nodeManagement.nodeEditDrawer.username')}
                        variant="outlined"
                        margin="normal"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        label={t('nodeManagement.nodeEditDrawer.password')}
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handlePasswordVisibilityToggle} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        fullWidth
                        label={t('nodeManagement.nodeEditDrawer.privateKeyContent')}
                        variant="outlined"
                        margin="normal"
                        value={privateKeyContent}
                        onChange={handlePrivateKeyChange}
                        multiline
                        rows={4}
                    />

                    <TextField
                        fullWidth
                        label={t('nodeManagement.nodeEditDrawer.remotePath')}
                        variant="outlined"
                        margin="normal"
                        value={remotePath}
                        onChange={(e) => setRemotePath(e.target.value)}
                    />

                    <TextField
                        fullWidth
                        label={t('nodeManagement.nodeEditDrawer.intermediatePath')}
                        variant="outlined"
                        margin="normal"
                        value={intermediatePath}
                        onChange={(e) => setIntermediatePath(e.target.value)}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                        <Button variant="outlined" color="secondary" onClick={onClose}>
                            {t('nodeManagement.nodeEditDrawer.cancel')}
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            {t('nodeManagement.nodeEditDrawer.submit')}
                        </Button>
                    </Box>
                </Box>
            </Drawer>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000} // auto hide after 5s
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default NodeEditDrawer;
