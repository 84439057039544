// src/components/NodeManagement/Pagination.js

import React from 'react';
import { Pagination as MuiPagination, Box, Typography } from '@mui/material';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (event, value) => {
    if (value >= 1 && value <= totalPages) {
      onPageChange(value);
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        right: '45px',
        bottom: '45px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
      }}
    >
      <MuiPagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        variant="outlined"
        shape="rounded"
        siblingCount={1}
        boundaryCount={1}
      />
      <Typography variant="body1" sx={{ ml: 3, fontWeight: 'bold', color: '#555' }}>
        Page {currentPage} of {totalPages}
      </Typography>
    </Box>
  );
};

export default Pagination;
