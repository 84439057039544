// src/contexts/NodeManagementContext.js

import React, { createContext, useContext, useState } from 'react';

const NodeManagementContext = createContext();

export const NodeManagementProvider = ({ children }) => {
  const [nodes, setNodes] = useState([]);

  return (
    <NodeManagementContext.Provider value={{ nodes, setNodes }}>
      {children}
    </NodeManagementContext.Provider>
  );
};

export const useNodeManagement = () => useContext(NodeManagementContext);
